import React from "react";
import { Helmet } from "react-helmet";
import { BRAND_NAME } from "../../../_common/content.js";

const HtmlAppleIcons: React.FC = () => (
    <Helmet>
        <meta name="apple-mobile-web-app-title" content={BRAND_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
            name="apple-mobile-web-app-status-bar-style"
            content="black-translucent"
        />
        <link
            rel="apple-touch-icon"
            href="/apple-touch-icon.png"
            sizes="180x180"
            type="image/png"
        />
    </Helmet>
);

export default HtmlAppleIcons;
