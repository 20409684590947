import { useContext } from "react";
import { SolarObjectsContext } from "./contexts/SolarObjectsContextProvider.js";
import { PickedDateContext } from "./contexts/DateContextProvider.js";
import {
    iconContainer,
    paramsGridContainer,
    paramValue,
    paramKey,
    paramsContainer,
    flexCenter,
    appearancesContainer,
    middleParamValue,
    moonPositionMessage,
} from "./Params.css.js";
import { GeoLocationContext } from "./contexts/GeoLocationContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { abbrevieteDegrees } from "../../helpers/utils.js";
import Icon from "../svg/Icon.js";

const formatTime = (m: number): string => {
    const hh = Math.floor(m / 60);
    const hours = `${hh}`.padStart(2, "0");
    const mins = `${m - hh * 60}`.padStart(2, "0");
    return hours + ":" + mins;
};

const Params: React.FC = () => {
    const {
        magnitude: magnitudeLabel,
        size,
        illumination: illuminationLabel,
        age: ageLabel,
        days,
    } = useContext(MoonCalendarContentContext);
    const { getParams } = useContext(SolarObjectsContext);
    useContext(GeoLocationContext);
    const { _pickedDate } = useContext(PickedDateContext);

    const { illumination, age, angularSize, magnitude } =
        getParams(_pickedDate);

    return (
        <div className={paramsContainer}>
            <MoonAppearances />
            <div className={paramsGridContainer}>
                <Param k={magnitudeLabel} v={magnitude.toFixed(1)} m />
                <Param k={size} v={abbrevieteDegrees(angularSize)} />
                <Param
                    k={illuminationLabel}
                    v={illumination.toFixed(1) + "%"}
                    m
                />
                <Param k={ageLabel} v={age.toFixed(1)} s={days} />
            </div>
        </div>
    );
};

interface ParamProps {
    k: string;
    v: string;
    s?: string;
    m?: boolean;
}

const Param: React.FC<ParamProps> = ({ k, v, s, m }) => {
    return (
        <>
            <span className={paramKey}>{k + ":"}</span>
            <span
                className={m ? middleParamValue : paramValue}
                suppressHydrationWarning
            >{`${v}${s ? ` ${s}` : ""}`}</span>
        </>
    );
};

const MoonAppearances: React.FC = () => {
    const { getAppearances } = useContext(SolarObjectsContext);
    const { _pickedDate } = useContext(PickedDateContext);
    const { pickedGeoLocation } = useContext(GeoLocationContext);
    const moonCalendarContent = useContext(MoonCalendarContentContext);
    const { setTime, riseTime, culmTime, setAz, riseAz, culmAng } =
        moonCalendarContent;
    const appearances = getAppearances(_pickedDate);

    if (!pickedGeoLocation) {
        return <div className={appearancesContainer} />;
    }
    const params = {
        set: { time: setTime, a: setAz },
        rise: { time: riseTime, a: riseAz },
        culm: { time: culmTime, a: culmAng },
    };
    return (
        <div className={appearancesContainer}>
            {appearances.map((a, i) =>
                typeof a === "string" ? (
                    <div key={a} className={moonPositionMessage}>
                        {moonCalendarContent[a]}
                    </div>
                ) : (
                    <div key={a.kind + i}>
                        <div className={flexCenter} title={params[a.kind].time}>
                            <span className={iconContainer}>
                                <Icon k={a.kind} />
                            </span>
                            <span suppressHydrationWarning>
                                {formatTime(a.time)}
                            </span>
                        </div>
                        <div className={flexCenter} title={params[a.kind].a}>
                            <span className={iconContainer}>
                                <Icon
                                    k={
                                        a.kind === "culm"
                                            ? "altitude"
                                            : "azimuth"
                                    }
                                />
                            </span>
                            <span>{`${a.a}°`}</span>
                        </div>
                    </div>
                ),
            )}
        </div>
    );
};

export default Params;
